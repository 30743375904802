import React from 'react'
import './style.scss'
const ScrumChartImage = () =>{
    return(
        <svg className="delay play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 592.771 344.212">

<g id="Group_935" data-name="Group 935" transform="translate(-727 -284.299)">
<g id="Group_934" data-name="Group 934" transform="translate(512 370)">
<g id="Group_933" data-name="Group 933" transform="translate(180 -376.464)">
<path id="Path_738" data-name="Path 738" className="clsdf-1" d="M8184.683-5228.771v247.037h464.507" transform="translate(-8030.564 5529.275)"></path>
<path id="Path_739" data-name="Path 739" className="clsdf-2" d="M8178.169-5229.565h11.55l-5.556-10.672Z" transform="translate(-8030 5531)"></path>
<path id="Path_740" data-name="Path 740" className="clsdf-2" d="M0,10.474H11.769L6.107,0Z" transform="translate(627.771 541.514) rotate(90)"></path>
</g>
<text id="PRODUCTIVITY" className="clsdf-3" transform="translate(215 43)"><tspan x="0" y="0">PRODUCTIVITY</tspan></text>
<text id="AGILENESS" className="clsdf-3" transform="translate(710 199)"><tspan x="0" y="0">AGILENESS</tspan></text>
<g id="Rectangle_268" data-name="Rectangle 268" className="clsdf-4" transform="translate(411.5 101.5) rotate(90)">
<rect className="clsdf-8" width="32" height="129"></rect>
<rect className="clsdf-9" x="-1.5" y="-1.5" width="35" height="132"></rect>
</g>
<text id="Waterfall_" data-name="Waterfall?" className="clsdf-5" transform="translate(308 123)"><tspan x="0" y="0">Waterfall?</tspan></text>
<path id="Path_743" data-name="Path 743" className="clsdf-6" d="M8158-4957.182s16.04-28.08,56.836-28.08,19.43,44.965,45.952,45.952,20.293-21.612,32.036-32.036,24.585-12.757,39.525-6.446,20.007,24.422,25.992,38.482,33.837,62.528,64.947,61.812,59.089-78.154,69.521-174.261,67.182-149.79,67.182-149.79" transform="translate(-7850 5134)"></path>
<path id="Path_744" data-name="Path 744" className="clsdf-7" d="M8178.168-5225.427h14.494l-6.973-14.81Z" transform="translate(-8842.262 -1826.746) rotate(43)"></path>
<g id="Rectangle_269" data-name="Rectangle 269" className="clsdf-4" transform="translate(637.5 208.5) rotate(90)">
<rect className="clsdf-8" width="32" height="129"></rect>
<rect className="clsdf-9" x="-1.5" y="-1.5" width="35" height="132"></rect>
</g>
<text id="Scrumbut" className="clsdf-5" transform="translate(534 230)"><tspan x="0" y="0">Scrumbut</tspan></text>
<g id="Rectangle_270" data-name="Rectangle 270" className="clsdf-4" transform="translate(727.5 -33.5) rotate(90)">
<rect className="clsdf-8" width="32" height="82"></rect>
<rect className="clsdf-9" x="-1.5" y="-1.5" width="35" height="85"></rect>
</g>
<text id="Agile" className="clsdf-5" transform="translate(667 -12)"><tspan x="0" y="0">Agile</tspan></text>
</g>
</g>
</svg>
    )
}

export default ScrumChartImage