import React,{useState,useEffect} from 'react'
import ScrumChartImage from '../../assets/images/scrum-chart-image'
import LandingHeader from '../header'
import {Link} from 'gatsby'
import queryString from 'query-string'
import './style.scss'
import Helmet from 'react-helmet'
const ScrumOrScrumbut = ()=>{
    const [prevpage,setPrevpage] = useState(null)

    useEffect(()=>{
    if(window !== undefined){
        const x=queryString.parse(window.location.search).trackingCode
        if(x !== undefined){
            setPrevpage(window.atob(queryString.parse(window.location.search).trackingCode))
        }
       
    }
      
    },[])
    return(
        <div className="body-container-wrapper scrum-vs-scrumbut">
               <Helmet defer={false}>
                <title>Scrum vs ScrumBut | JDSolutions</title>
                <meta property="og:title" content={`Scrum vs ScrumBut | JDSolutions`} />
                <meta property="og:type" content="page" />
                {/* <meta property="og:image" content={bigbanner} />  */}
            </Helmet>
            <div className="body-container container-fluid">
            <LandingHeader fixed={true}/>
            <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-cell main"  >

            <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
            <div className="row-fluid ">
            <div className="">
            <div className="span12 widget-span widget-type-cell "  >

            <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-cell sec-wrapper"  >

            <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-raw_html ">
            <div className="cell-wrapper layout-widget-wrapper">
       
            <h2 className="en-text">Scrum vs ScrumBut <br/> <span>Which one are you ?</span></h2>
            
           <ScrumChartImage/>
            <h4 className="en-text">How Scrum is your company - check our questionnaire that will give you the answer.</h4>
        
            </div>
            </div>
            </div>
            </div>

            <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-rich_text footer" >
            <div className="cell-wrapper layout-widget-wrapper">
                <span>
            <Link to="/are-you-scrum" className="red-button" rel=" noopener">
                 <span className="en-text">Yes, I want to test how scrum I am </span>
             </Link> 
             <br/>
             It takes only 3 minutes
             <br/> 
             <Link to={prevpage ? prevpage : '/'} className="underline-btn">No thanks We don't need scrum</Link>
             </span>
            </div>
            </div>
            </div>
            </div>

            </div>
            </div>
            </div>

            </div>
            </div>
            </div>
            </div>

            </div>
            </div>
            </div>
            </div> 
        </div>
    )
}
export default ScrumOrScrumbut